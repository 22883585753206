import { navigate } from "gatsby";
import React from "react";
import Loader from "../components/Layout/Loader";
import { useRoutes } from "../utils/routes";

const Home: React.FC = () => {
  const routes = useRoutes();
  if (!routes) return null;
  if (typeof window === `undefined`) return null;
  navigate(routes[0].title);
  return <Loader />;
};

export default Home;
