import styled from "@emotion/styled";
import React from "react";

import "./loader.css";
import Logo from "../ui/Logo";

const Loader: React.FC = () => {
  return (
    <Background>
      <Wrapper>
        <Logo big />
      </Wrapper>
    </Background>
  );
};

export default Loader;

const Background = styled.div`
  opacity: 1;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #111;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loader 0.3s ease forwards;
`;

const Wrapper = styled.div`
  animation: loader 2s ease alternate infinite;
`;
